<template>
    <div id="app" class="result"  ref="container" >

        <div id="divcss5" :style="{marginTop: marginTop}">
            <van-sticky :container="container" >
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="phone"
                            type="tel"
                            name="手机号"
                            label="手机号"
                            :rules="[{ validator:validator}]"
                            readonly
                    />
                    <div style="margin-top: 10px;">
                        <van-button round block style="background-color: #94cdfa;" native-type="submit">领取</van-button>
                    </div>
                </van-form>

            </van-sticky>
        </div>
    </div>
</template>

<style>
    .result{
        background:url("../../assets/couponbj3.png");
        min-height: 500px;
        width:100%;
        height:100%;
        position:fixed;
        background-size:100% 100%;}

    #divcss5 {
        margin:0 auto;margin-top:120%;width:280px;height:80px;
    }
</style>

<script>
    import { getPhoneNumber, listPhone } from "@/api/login";
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Button } from 'vant';
    import { Dialog, Image } from 'vant';
    Vue.use(Form);
    Vue.use(Field);
    Vue.use(Button);
    Vue.use(Image);

    import { Sticky } from 'vant';

    Vue.use(Sticky);

    export default {
        components: {},
        data() {
            return {
                phone: null,
                token: this.$route.query.token,
                mbrChannelInfo: this.$route.query.mbrChannelInfo,
                container: null,
                marginTop: null,
            };
        },
        mounted() {
            this.container = this.$refs.container;
            const originalHeight = document.documentElement.clientHeight ||document.body.clientHeight;
            window.onresize = ()=>{
                return(()=>{
                    //键盘弹起与隐藏都会引起窗口的高度发生变化
                    const resizeHeight=document.documentElement.clientHeight || document.body.clientHeight;
                    //console.log("进入到判断页面高度=========");
                    //console.log("页面初始高度========="+originalHeight);
                    //console.log("软键盘弹起高度========="+resizeHeight);
                    if(resizeHeight-0<originalHeight-0){
                        //当软键盘弹起，在此处操作
                        //console.log("进入到软键盘弹起=========");
                        //document.querySelector('result').setAttribute('style', 'height:'+originalHeight+'px;');
                        this.marginTop='80%';
                    }else{
                        //当软键盘收起，在此处操作
                        //console.log("进入到软键盘收起=========");
                        //document.querySelector('result').setAttribute('style', 'height:100%;');
                        this.marginTop="120%";
                    }
                })()

            }
        },
        // 解决安卓手机调出来软键盘，屏幕背景被挤压的问题
        created() {
            const distance = this.$getViewportSize().height * 0.6;
            this.marginTop = distance+"px";
            this.getList();
            // 获取当前可视区域的高度
            const height = document.documentElement.clientHeight;
            // 在页面整体加载完毕时
            window.onload = function () {
                // 把获取到的高度赋值给根div
                document.getElementById('app').style.height = `${height}px`;
            };
        },
        methods: {
            /** 查询用户列表 */
            getList() {
                listPhone(this.token).then(response => {
                    const code = response.code;
                    if(code == 665){
                        Dialog({ message: '手机号获取失败' });
                    }
                    this.phone = response.data;
                });
            },
            // 校验函数返回 true 表示校验通过，false 表示不通过
            validator(val) {
                return /^1(3|4|5|7|8|9)\d{9}$/.test(val);
            },
            onSubmit(values) {
                getPhoneNumber(this.phone,this.mbrChannelInfo,3).then(response => {
                    const code = response.code;
                    if(code == 666){
                        Dialog({ message: '请重新领取' });
                    }else if(code == 667 ){//今日已领取
                        Dialog({ message: '抱歉，您领取优惠的次数已超限' });
                    }else if(code == 668){//未注册
                        Dialog.confirm({ message: '很遗憾，您还不是云闪付用户。点击“确定”，下载云闪付，注册绑卡后，参与活动' })
                            .then(() => {
                                window.location.href = "https://youhui.95516.com/hybrid_v4/html/help/download.html";
                            })
                            .catch(() => {
                            });
                    }else if(code == 669){
                        Dialog({ message: '很遗憾，券已领取完毕，欢迎明天再次参与' });
                    }else if(code ==670 || code == 671){//活动过期或者优惠券不存在  活动id错误  系统错误
                        Dialog({ message: '您抢券的时间不在活动时间范围之内，更多详细规则，可联系客服：0531-86165717' });
                    }else if(code == 200){
                        Dialog({ message: '恭喜您抢券成功，请前往云闪付APP“我的”-“奖励”查看票券，并进行核销使用' });
                    }else {
                        Dialog({message : '操作失败，请联系客服：0531-86165717'});
                    }


                }).catch((e) => {});
            },
        },
    };
</script>
